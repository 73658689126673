<template>
  <li :class="{ 'message-sent': msgFlag, 'message-received': !msgFlag }">
    <div class="message-avatar">
      <img v-if="profilePic" class="round-image" :src="profilePic" />
      <img v-else class="round-image" src="@/images/default-avatar.png" />
    </div>
    <div
      :class="{
        'message-text': true,
        'right-talk-bubble': msgFlag,
        'left-talk-bubble': !msgFlag,
        'tri-right': true,
        'right-bubble': msgFlag,
        'left-bubble': !msgFlag,
        'right-top': msgFlag,
        'left-top': !msgFlag,
      }"
    >
      <div class="talktext">
        <p>{{ msg.content }}</p>
      </div>
    </div>
    <div class="timetext">
      <div class="message-empty">&nbsp;</div>
      <p>{{ modifyDate(msg.time.toDate()) }}</p>
    </div>
    <div class="message-empty">&nbsp;</div>
  </li>
</template>

<script>
import { ref, onMounted, watchEffect } from "vue";

export default {
  props: ["msg", "senderPosition", "chatterProfilePic", "selfProfilePic"],
  emits: ["chatChanged"],
  async setup(props, { emit }) {
    const msgFlag = ref(false);
    const profilePic = ref(null);

    if (props.senderPosition == props.msg.senderID) {
      msgFlag.value = true;
    } else {
      msgFlag.value = false;
    }

    const modifyDate = (dateHold) => {
      const now = new Date(Date.now())
      let result = String(dateHold.getHours()).padStart(2, '0') + ":" + String(dateHold.getMinutes()).padStart(2, '0')
      const diffTime = Math.abs(dateHold - now);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      let msgTime = [
        dateHold.getMonth() + 1,
        dateHold.getDate(),
        dateHold.getFullYear()
      ]
      let nowTime = [
        now.getMonth() + 1,
        now.getDate(),
        now.getFullYear()
      ]
      if(diffDays == 1 && msgTime[1] != nowTime[1]){
        result = "yesterday " + result
      }
      else if(diffDays > 1 && diffDays <= 6) {
        let msgDay
        switch(diffDays){
          case 0:
            msgDay = "Sunday"
            break;
          case 1:
            msgDay = "Monday"
            break;
          case 2:
            msgDay = "Tuesday"
            break;
          case 3:
            msgDay = "Wednesday"
            break;
          case 4:
            msgDay = "Thursday"
            break;
          case 5:
            msgDay = "Friday"
            break;
          case 6:
            msgDay = "Saturday"
            break;
        }
        result = msgDay + " " +  result
      }
      else if(diffDays > 6){
        result = String(msgTime[0]).padStart(2, '0') + "/" + String(msgTime[1]).padStart(2, '0') + "/" + msgTime[2] + " " + result
      }
      return result
    };

    watchEffect(() => {
      if (msgFlag.value) {
        profilePic.value = props.selfProfilePic;
      } else {
        profilePic.value = props.chatterProfilePic;
      }
    });

    onMounted(() => {
      emit("chatChanged");
    });

    return { msgFlag, profilePic, modifyDate };
  },
};
</script>

<style scoped>
* {
  white-space: pre-wrap;
}

.message-received {
  display: flex;
  margin: 5px 0;
}

.message-avatar {
  flex: 0 1 5%;
}

.message-text {
  flex: 0 0 auto;
  align-self: left;
  max-width: 36vw;
}

.message-empty {
  flex: 1 1 auto;
}

.message-sent {
  display: flex;
  margin: 5px 0;
  flex-direction: row-reverse;
}

.timetext{
  margin: 12px 0;
  display: flex;
  flex-direction: column;
}

.timetext p{
  flex: 0 0 auto;
  font-size: 70%;
  color: grey;
}

.talktext {
  padding: 1em;
  text-align: left;
  line-height: 1.5em;
  width: 100%;
}

.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  width: 100%;
}

.left-talk-bubble {
  margin: 10px 40px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
  height: auto;
  border-radius: 30px;
}

.right-talk-bubble {
  margin: 10px 40px;
  margin-left: 5px;
  display: inline-block;
  position: relative;
  height: auto;
  border-radius: 30px;
}

.tri-right.left-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -20px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color:white transparent transparent transparent;
}

.tri-right.right-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: var(--purple-l) transparent transparent transparent;
}

.right-bubble {
  background-color: var(--purple-l);
  color: white
}

.left-bubble {
  background-color: white;
  color: black;
}

@media screen and (max-width: 768px){
  .message-avatar {
    display: none;
  }

  .right-talk-bubble,
  .left-talk-bubble {
    border-radius: 10px;
    margin: 10px 20px;
  }

  .talktext {
    padding: 10px;
  }
}
</style>
